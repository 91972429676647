import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Flex } from 'core/ui';
import { useDevice } from 'core/hooks/useDevice';
import { trackRvCardClick } from 'tracking/rvs/TrackRVCardClick';

import defer from 'core/helpers/defer';
import useSearchFilters from '../../hooks/useSearchFilters';
import { SearchResultShape } from '../../shapes/SearchResults';
import { useResultHover } from '../../hooks/useResultHover';

import RvRow from '../RvRow';
import NextPageForm from './NextPageForm';

const ResultItem = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  border-top: 1px solid #d9d9d9;
  padding-top: 20px;
  margin-bottom: 20px;
`;

const LoadingOverlay = styled.div`
  height: 550px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  background: rgba(255, 255, 255, 0.7);
`;

// this is somewhat fragile and dependent on the markup
const doNotTrack = ['path', 'svg', 'circle', 'button'];
const doNotTrackClasses = ['swiper-button-next', 'swiper-button-prev', 'delivery-overlay', 'total-price'];
// track clicking on RV card but don't track carousel or favorite clicks
const handleClick = (e, index, rv, filters, pagination) => {
  if (
    !doNotTrack.includes(e.target.tagName.toLowerCase()) &&
    !doNotTrackClasses.find(classListEl => e.target.className.indexOf(classListEl) > -1)
  ) {
    defer(() => trackRvCardClick(index, rv, filters, pagination));
  }
};

function SearchResultsGrid(props) {
  const {
    loading,
    results,
    searchLocation,
    pagination,
    mapLoadingVisible,
    isFleet,
    showDistance,
  } = props;
  const { isMobile } = useDevice();


  const { toggleHoveredResult } = useResultHover();
  const { filters } = useSearchFilters();

  return (
    <>
      <Flex id="search_results_grid" flexWrap="wrap" style={{ position: 'relative', zIndex: 0 }}>
        {!!loading && <LoadingOverlay />}
        {results.map((result, i) => (
          <React.Fragment key={result.id}>
            <ResultItem
              onMouseEnter={() => defer(() => toggleHoveredResult(result.id))}
              onMouseLeave={() => defer(() => toggleHoveredResult(null))}
              onClick={e => handleClick(e, i, result, filters, pagination)}
            >
              <RvRow
                // Preload the first 2 images
                lazyLoad={i > 1}
                searchLocation={searchLocation}
                rv={result}
                imageLoading={mapLoadingVisible}
                isMobile={isMobile}
                isFleet={isFleet}
                position={i + 1}
                loading={loading}
                showDistance={showDistance}
              />
            </ResultItem>
          </React.Fragment>
        ))}
      </Flex>

      <div className="search-results__grid__item">
        <NextPageForm />
      </div>
    </>
  );
}

SearchResultsGrid.propTypes = {
  loading: PropTypes.bool,
  results: PropTypes.arrayOf(PropTypes.shape(SearchResultShape)),
  searchLocation: PropTypes.string,
};

SearchResultsGrid.defaultProps = {
  loading: false,
  results: [],
  searchLocation: '',
};

export default React.memo(SearchResultsGrid);
