import PropTypes from 'prop-types';

export const SearchResultLocationShape = {
  name: PropTypes.string,
  distance: PropTypes.number,
  lat: PropTypes.number,
  lng: PropTypes.number,
};

export const SearchResultReviewsShape = {
  score: PropTypes.number,
};

export const SearchResultThumbnailShape = {
  filename: PropTypes.string,
  url: PropTypes.string,
};

export const SearchResultAttributesShape = {
  result_id: PropTypes.number,
  how_many_it_sleeps: PropTypes.number,
  rate: PropTypes.number.isRequired,
  type: PropTypes.string,
  headline: PropTypes.string,
  rv_make_model: PropTypes.string,
  rv_year: PropTypes.number,
  is_instant_book: PropTypes.bool.isRequired,
  favorited: PropTypes.bool.isRequired,
  location: PropTypes.shape(SearchResultLocationShape).isRequired,
  reviews: PropTypes.shape(SearchResultReviewsShape).isRequired,
  thumbnail: PropTypes.shape(SearchResultThumbnailShape).isRequired,
};

export const SearchResultShape = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  attributes: PropTypes.shape(SearchResultAttributesShape).isRequired,
};

export const SearchPaginationShape = {
  currentPage: PropTypes.number,
  totalResults: PropTypes.number,
  perPage: PropTypes.number,
};

export const SearchPagerShape = {
  totalPages: PropTypes.number,
  pages: PropTypes.arrayOf(PropTypes.number),
  currentPage: PropTypes.number,
  firstPage: PropTypes.number,
  lastPage: PropTypes.number,
  previousPage: PropTypes.number,
  nextPage: PropTypes.number,
  hasPreviousPage: PropTypes.bool,
  hasNextPage: PropTypes.bool,
  totalResults: PropTypes.number,
};

export const SearchResultsShape = {
  loading: PropTypes.bool,
  error: PropTypes.shape({}),
  results: PropTypes.arrayOf(PropTypes.shape(SearchResultShape)),
  pagination: PropTypes.shape(SearchPagerShape),
  variant: PropTypes.string,
};
