import styled from 'styled-components';

import { Container } from 'core/styles/utils';

export const LocationHeading = styled.h1`
  font-size: 1.75rem;
  margin-top: 10px;
  margin-bottom: 0;
`;

export const SearchResultsWrapper = styled(Container)`
  width: 100%;
  padding: 0 !important;
`;

export const SearchLoader = styled.div`
  z-index: 2;
  position: ${props => (props.absolute ? 'absolute' : 'fixed')};
  top: ${props => (props.absolute ? '100px' : '50%')};
  left: 50%;
  background: #0f6cb6;
  border-radius: 10px;
  padding: 25px;
  width: 112px;
  opacity: 0.5;
  margin-left: -56px;
  margin-top: -25px;

  > div {
    &,
    &::before,
    &::after {
      border-radius: 50%;
      width: 2.5em;
      height: 2.5em;
      animation: resultsLoader 1.8s infinite ease-in-out;
      animation-fill-mode: both;
    }

    margin: 0 auto;
    font-size: 0.625rem;
    position: absolute;
    top: -10px;
    left: 43px;
    text-indent: -9999em;
    transform: translateZ(0);
    animation-delay: -0.16s;

    &::before {
      left: -3.5em;
      animation-delay: -0.32s;
    }

    &::after {
      left: 3.5em;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
    }
  }

  @keyframes resultsLoader {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em #fff;
    }

    40% {
      box-shadow: 0 2.5em 0 0 #fff;
    }
  }
`;

export const SearchResultsColumn = styled.div`
  display: ${({ searchMapFullScreen }) => (searchMapFullScreen ? 'none' : 'block')};
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;

  /* need custom breakpoints here so map doesn't overlap price/favorite button */
  @media screen and (min-width: 1072px) {
    width: 60%;
  }

  @media screen and (min-width: 1299px) {
    width: 50%;
  }
`;

export const SearchMapColumn = styled.div`
  display: block;
  width: ${({ searchMapFullScreen }) => (searchMapFullScreen ? '100%' : '0%')};
  position: relative;
  background-color: #e5e3df;

  /* need custom breakpoints here so map doesn't overlap price/favorite button */
  @media screen and (min-width: 1072px) {
    width: ${({ searchMapFullScreen }) => (searchMapFullScreen ? '100%' : 'calc(40% - 10px)')};
  }
  @media screen and (min-width: 1299px) {
    width: ${({ searchMapFullScreen }) => (searchMapFullScreen ? '100%' : 'calc(50% - 10px)')};
  }
`;

export const HeaderWrapper = styled.div`
  padding-left: 0;
  width: 100%;
`;

export const FooterAdWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 3rem;
  position: relative;
`;
