import React from 'react';
import { useImpressionTracker } from 'impression-tracker-react-hook';

import * as Formatters from 'core/helpers/Formatters';
import { Flex, Box } from 'core/ui';
import ToggleFavoriteButton from 'components/ToggleFavoriteButton';

import { eventLogger, handleError } from '../../../tracking/PromotedAi';

import Thumbnail from './Thumbnail';
import TotalPrice from './TotalPrice';
import Ratings from '../RVCard/Ratings';

import {
  Card,
  MetaWrapper,
  RateText,
  RateWrapper,
  RatingsWrapper,
  MobileBullet,
  StyledInstantBookIcon,
  MetaLocationBox,
  ThumbnailHeadlineOverlay,
  RvDetailsSection,
  LinkOverlay,
  FavoriteContainer,
  StrikethroughSpan,
  BoldSpan,
  RvTotalPrice,
} from './RvRow.styles';

function RvRow(props) {
  const {
    isInstantBook,
    offersDelivery,
    rvId,
    year,
    makeModel,
    classType,
    sleeps,
    nightlyRate,
    reviewScore,
    searchLocation,
    distanceFromSearchLocation,
    isMinimalCard,
    imageLoading,
    isMobile,
    images = [],
    lazyLoadImage: lazyLoad,
    position,
    length,
    numberOfReviews,
    ownerId,
    make,
    model,
    headline,
    isFavorited,
    lat,
    lng,
    showDistance,
    isNewListing,
    pricingBreakdown,
  } = props;

  const href = `/rvs/details/${rvId}`;
  const linkTarget = isMobile ? '_self' : `rv_${rvId}`;

  const [containerRef, impressionId, logImpressionFunctor] = useImpressionTracker({
    enable: true,
    contentId: rvId,
    handleError,
    logImpression: eventLogger.logImpression,
  });

  function handleClick() {
    logImpressionFunctor();
    eventLogger.logClick({
      impressionId,
      contentId: rvId,
      targetUrl: href,
    });
  }

  return (
    <Card data-id="rv-card" ref={containerRef}>
      <LinkOverlay
        aria-labelledby={linkTarget}
        href={href}
        onClick={handleClick}
        target={linkTarget}
        data-event-category="RV Card"
        data-event-action="RV Card"
        data-event-label="RV Card"
        data-event-index={position}
      />
      <Thumbnail
        {...{
          lazyLoad,
          images,
          offersDelivery,
          makeModel,
          isMinimalCard,
          imageLoading,
          isMobile,
          rvId,
          ownerId,
          year,
          make,
          model,
          headline,
          nightlyRate,
          isFavorited,
          classType,
          lat,
          lng,
          sleeps,
          href,
          linkTarget,
          position,
        }}
      />
      <MetaWrapper>
        <Flex flexDirection="column">
          <ThumbnailHeadlineOverlay>
            <h5>
              {year} {classType}
            </h5>
            <h4>{makeModel}</h4>
          </ThumbnailHeadlineOverlay>
          <RvDetailsSection>
            <Box>
              Sleeps {sleeps} <MobileBullet /> {length}ft
            </Box>
            {searchLocation && showDistance && (
              <MetaLocationBox>
                <span>
                  {Formatters.float(distanceFromSearchLocation, 1)} miles from {searchLocation}
                </span>
              </MetaLocationBox>
            )}
          </RvDetailsSection>
          <RatingsWrapper isInstantBook={isInstantBook}>
            <Ratings
              score={reviewScore}
              numberOfReviews={numberOfReviews}
              isNewListing={isNewListing}
            />
          </RatingsWrapper>
        </Flex>
        <Flex flexDirection="column">
          <FavoriteContainer>
            <ToggleFavoriteButton
              {...{
                rvId,
                ownerId,
                year,
                make,
                model,
                headline,
                nightlyRate,
                offersDelivery,
                isFavorited,
                classType,
                location: {
                  lat,
                  lng,
                },
                sleeps,
              }}
              isRvRow
            />
          </FavoriteContainer>
          <RateWrapper>
            <RateText>
              {isInstantBook && <StyledInstantBookIcon />}
              {pricingBreakdown?.discounts?.value < 0 && (
                <>
                  <StrikethroughSpan>
                    {Formatters.currency(pricingBreakdown?.non_discounted_rate?.value)}
                  </StrikethroughSpan>{' '}
                </>
              )}
              <BoldSpan>{Formatters.currency(nightlyRate)}</BoldSpan>
              <span>/night {isMobile && <MobileBullet />}</span>
            </RateText>
            <RvTotalPrice>
              <TotalPrice
                nightlyRate={nightlyRate}
                pricingBreakdown={pricingBreakdown}
                text="total before tax"
              />
            </RvTotalPrice>
          </RateWrapper>
        </Flex>
      </MetaWrapper>
    </Card>
  );
}

export default RvRow;
