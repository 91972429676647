import SafeTracking from 'tracking/SafeTracking';

import { isDrivable, isTowable } from 'core/helpers/RV';
import {
  kitchenAmenities,
  bathroomAmenities,
  entertainmentAmenities,
  temperatureAmenities,
  otherAmenities,
} from 'search/helpers/Filters';

const getSelectedAmenities = (amenities = {}) => Object.keys(amenities).filter(a => !!amenities[a]);

// we get an array of amenities but don't know what category they belong to
// this filters array based on imported array
const getAmenities = (amenities = {}, typeArray) => {
  const allSelectedAmenities = getSelectedAmenities(amenities)
  return allSelectedAmenities.filter(amenity => typeArray.includes(amenity))
};

const getDrivableList = rvClasses => {
  const results = [];
  Object.keys(rvClasses).forEach(rvClass => {
    if (isDrivable({ name: rvClass }) && rvClasses[rvClass]) {
      results.push(rvClass);
    }
  });

  return results;
};

const getTowableList = rvClasses => {
  const results = [];
  Object.keys(rvClasses).forEach(rvClass => {
    if (isTowable({ name: rvClass }) && rvClasses[rvClass]) {
      results.push(rvClass);
    }
  });

  return results;
};

const getRentalOptionsList = (delivery, stationary) => {
  const results = [];
  if (delivery) results.push('Offers Delivery');
  if (stationary) results.push('Offers Stationary Rental');
  return results;
};

const getRentalRulesList = (pets, smoking) => {
  const results = [];
  if (pets) results.push('Allows Pets');
  if (smoking) results.push('Allows Smoking');
  return results;
};

export const trackRvCardClick = (index, rv, filters, pagination) => {
  SafeTracking.track(
    'Search Result Selected',
    {
      resultIndex: index + 1, // switch to 1 based index for tracking
      pageNumber: pagination?.currentPage,
      rvName: rv.attributes.rv_make_model,
      rvId: rv.id,
      pricePerNight: rv.attributes.rate,
      rating: rv.attributes.reviews.score
        ? (rv.attributes.reviews.score / (rv.attributes.reviews.max_score / 5)).toFixed(1)
        : '',
      distance: rv.attributes.location.distance ? rv.attributes.location.distance.toFixed(1) : '',
      hasDelivery: rv.attributes.delivery,
      isInstantBook: rv.attributes.is_instant_book,
      isFavorite: rv.attributes.favorited,
      priceFilterMin: filters.min || '',
      priceFilterMax: filters.max || '',
      drivableRvFilter: filters.rvClass ? getDrivableList(filters.rvClass) : '',
      towableRvFilter: filters.rvClass ? getTowableList(filters.rvClass) : '',
      instantBookFilter: filters.instantBook || false,
      keywordFilter: filters.keywords || '',
      sleepsFilter: filters.sleeps,
      rentalOptionsFilter: getRentalOptionsList(
        filters.delivery || false,
        filters.isStationary || false
      ),
      rentalRulesFilter: getRentalRulesList(filters.pets || false, filters.smoking || false),
      kitchenFilter: getAmenities(filters.amenities, kitchenAmenities),
      bathroomFilter: getAmenities(filters.amenities, bathroomAmenities),
      entertainmentFilter: getAmenities(filters.amenities, entertainmentAmenities),
      temperatureFilter: getAmenities(filters.amenities, temperatureAmenities),
      otherFilter: getAmenities(filters.amenities, otherAmenities),
      vehicleLengthFilterMin: filters.minLength || '',
      vehicleLengthFilterMax: filters.maxLength || '',
      vehicleYearFilterMin: filters.fromYear || '',
      vehicleYearFilterMax: filters.toYear || '',
      ...(filters?.towingVehicle?.towingWeight && {
        towingVehicle: {
          year: filters.towingVehicle.year,
          make: filters.towingVehicle.make,
          model: filters.towingVehicle.model,
        },
      }),
    },
    { rvsIntegrations: { all: false, segment: true } }
  );
};
