import React, { createContext, useState, useMemo, useContext } from 'react';

const ResultHoverContext = createContext();

function ResultHoverProvider(props) {
  const [hoveredResultId, setResultHovered] = useState(null);
  const value = useMemo(() => {
    return {
      hoveredResultId,
      setResultHovered,
    };
  }, [hoveredResultId]);

  return <ResultHoverContext.Provider value={value} {...props} />;
}

function useResultHover() {
  const context = useContext(ResultHoverContext);
  if (!context) {
    throw new Error('useResultHover must be used within a ResultHoverProvider');
  }

  const { hoveredResultId, setResultHovered } = context;
  const toggleHoveredResult = id => setResultHovered(id);
  return {
    hoveredResultId,
    toggleHoveredResult,
  };
}

export { ResultHoverProvider, useResultHover };
