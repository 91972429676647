import { Swiper } from 'swiper/react/swiper-react';
import styled from 'styled-components';

import { Flex, Box, Button } from 'core/ui';
import { InstantBookIcon } from 'images/badges/rv/Instantbook';

export const Card = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  color: ${({ theme }) => theme.colors.darkBlue};
  text-decoration: none;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.medium`
    flex-direction: row;
  `};
`;

export const LinkOverlay = styled.a`
  bottom: 0;
  position: absolute;
  top: 0;
  outline: none;
  left: 0;
  right: 0;
  z-index: 1;
`;

export const ThumbnailWrapper = styled.div`
  position: relative;
  background-color: #d9d9d9;
  border-radius: 5px;

  --thumbnail-width: 100%;
  --thumbnail-height: 100%;
  ${({ theme }) => theme.medium`
    --thumbnail-width: ${({ isRvMap }) => (isRvMap ? '100%' : '300px')};
    --thumbnail-height: ${({ isRvMap }) => (isRvMap ? '100%' : '230px')};
  `};

  width: var(--thumbnail-width);
  height: var(--thumbnail-height);
`;

export const ThumbnailHeadlineOverlay = styled.div`
  padding: 0;

  h4 {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0;
    padding: 0;
    letter-spacing: 0.2px;
  }

  h5 {
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
    padding: 0;
    line-height: 1.29;
    letter-spacing: 0.1px;
    color: #8d8d8d;
  }

  ${({ theme }) => theme.medium`
    margin-bottom: 24px;
  `};
`;

export const ThumbnailFavoriteOverlay = styled.div`
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  z-index: 2;
  cursor: pointer;
`;

export const MetaWrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  margin-top: 16px;

  ${({ theme }) => theme.medium`
    flex-direction: row;
    padding-left: 20px;
    justify-content: space-between;
    margin-top: 0px;
    flex: 1;
  `};
`;

export const RatingsWrapper = styled(Box)`
  order: -1;
  font-size: 0.75rem;
  margin-bottom: 4px;

  svg {
    font-size: 0.75rem;
  }

  ${({ theme }) => theme.medium`
    order: 2;
    margin-right: auto;
    margin-top: auto;
    font-size: 1rem;
    margin-bottom: 0;

    svg {
      font-size: 1rem;
    }
  `};
`;

export const RateWrapper = styled(Box)`
  margin-top: auto;
  z-index: 2;
  display: flex;
  gap: 3px;
  align-items: center;
  @media screen and (min-width: 768px) {
    display: block;
  }
`;

export const RateText = styled.div`
  font-size: 1.125rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media screen and (min-width: 768px) {
    justify-content: flex-end;
  }
`;

export const StyledInstantBookIcon = styled(InstantBookIcon)`
  margin-right: 4px;
  width: 10px;

  ${({ theme }) => theme.medium`
    width: 14px;
  `};
`;

export const MobileBullet = styled.span`
  background-color: #8d8d8d;
  display: inline-block;
  border-radius: 50%;
  margin-top: -2px;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  height: 0.25rem;
  width: 0.25rem;
  vertical-align: middle;
`;

export const MetaLocationBox = styled(Box)`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 0;
  margin-top: 4px;

  ${({ theme }) => theme.medium`
    flex: 0 1 auto;
    min-width: 100%;
  `};
`;

export const RvDetailsSection = styled.div`
  font-size: 1rem;
  color: #414141;
`;

export const DeliveryOverlay = styled.div`
  color: white;
  background-color: #0e6cb5;
  position: absolute;
  left: 12px;
  top: 12px;
  z-index: 10001;
  font-size: 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 8px;
  font-weight: 600;
`;

export const SliderButton = styled(Button)`
  color: #fff;
  position: absolute;
  top: 0;
  z-index: 1;
  touch-action: none;
  height: 100%;

  &:first-of-type {
    padding-right: 24px;
  }

  &:last-of-type {
    right: 0;
    padding-left: 24px;
  }

  &:hover {
    color: #ccc;
  }
`;

export const FavoriteContainer = styled.div`
  margin-left: auto;
  display: none;

  ${({ theme }) => theme.medium`
    display: block;
    z-index: 1000;
  `};
`;

export const MapCardHeadline = styled.div`
  padding: 0;
  margin-bottom: 8px;

  h4 {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    line-height: 1.56;
    letter-spacing: 0.2px;
  }

  h5 {
    font-size: 12px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    line-height: 1.29;
    letter-spacing: 0.1px;
    color: #8d8d8d;
  }
`;

export const RvMapMetaWrapper = styled(Flex)`
  padding: 8px 12px 16px;
  display: flex;
  flex-direction: column;
`;

export const RvMapRatingsWrapper = styled(Box)`
  font-size: 0.5rem;

  svg {
    font-size: 0.5rem;
  }
`;

export const RvMapRateText = styled.div`
  font-size: 16px;
  display: flex;
  align-items: center;
`;

export const RvTotalPrice = styled.div`
  color: #8d8d8d;
  z-index: 2;
  text-align: right;
  white-space: nowrap;
  @media screen and (min-width: 768px) {
    font-size: 0.9rem;
  }
`;

export const StrikethroughSpan = styled.span`
  text-decoration: line-through;
  color: #8d8d8d;
`;

export const BoldSpan = styled.span`
  font-weight: 550;
`;

export const RvMapTotalPriceDiv = styled(RvTotalPrice)`
  margin-left: 4px;
`;

export const RvMapInstantBookIcon = styled(InstantBookIcon)`
  margin-right: 4px;
  width: 10px;
`;

export const SwiperContainer = styled(Swiper)`
  .swiper-slide img {
    display: block;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
  }

  /*
    initial (ssr) render has the first slide one position to the right for some reason, so we need to adjust it
    this is eventually corrected in js
  */
  .swiper-slide[data-swiper-slide-index='0'] {
    left: calc(var(--thumbnail-width) * -1);
  }

  .swiper-pagination-bullet {
    opacity: 0.6;
    background: rgb(255, 255, 255);
  }

  .swiper-pagination-bullet-active {
    opacity: 1;
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: white;

    &:after {
      content: '';
    }

    svg {
      color: white;
      transition: 0.2s ease-in-out;
      text-shadow: 5px 5px 10px black;

      &:hover,
      &:focus {
        color: #ccc;
        text-shadow: 3px 3px 5px black;
      }
    }
  }
`;
