import { useCallback } from 'react';

import useSearchFilters from './useSearchFilters';

const useSearchFiltersLinkProps = () => {
  const { changeSearchFilters, buildSearchUrl } = useSearchFilters();

  return useCallback(
    (page, pathname) => {
      const href = buildSearchUrl({ page, pathname });

      const onClick = (e) => {
        e.preventDefault();

        if (!page) {
          // last page of results will have a "next" button with page = `null`
          return;
        }

        changeSearchFilters({ page });
      };

      return {
        href,
        onClick,
      };
    },
    [changeSearchFilters, buildSearchUrl],
  );
};

export default useSearchFiltersLinkProps;
