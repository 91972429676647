import React, { useEffect, useState, useMemo } from 'react';
import { isEqual } from 'lodash-es';

import { canInstantBook } from 'core/helpers/RV';
import useRvImagePlaceholders from 'core/hooks/useRvImagePlaceholders';

import RvRow from './RvRow';
import RvMapCard from './RvMapCard';
import LoadingRow from './LoadingRow';

const sanitizeImages = images => (images || []).filter(Boolean);

export default React.memo(props => {
  const {
    rv: { id: rvId, attributes: rv },
    lazyLoad,
    searchLocation,
    isMobile,
    imageLoading = false,
    isMinimalCard = false,
    position,
    loading,
    showDistance,
    isFleet,
  } = props;

  const images = useMemo(() => sanitizeImages(rv.thumbnails), [rv.thumbnails]);

  // Adding placeholder images in local dev
  const { rvImageUrls: updatedRvImageUrls } = useRvImagePlaceholders(images);
  const [rvImages, setRvImages] = useState(updatedRvImageUrls);

  useEffect(() => {
    if (!isEqual(rvImages, updatedRvImageUrls)) {
      setRvImages(updatedRvImageUrls);
    }
  }, [updatedRvImageUrls, rvImages]);

  const Component = isMinimalCard ? RvMapCard : RvRow;

  if (loading) {
    return <LoadingRow />;
  }

  return (
    <Component
      images={rvImages}
      lazyLoadImage={lazyLoad}
      isFavorited={rv.favorited}
      isInstantBook={canInstantBook(rv.is_instant_book, rv.insurance_status)}
      offersDelivery={rv.delivery}
      rvId={rvId}
      year={rv.rv_year}
      make={rv.make}
      model={rv.model}
      headline={rv.headline}
      ownerId={(rv.owner || {}).id}
      makeModel={rv.rv_make_model}
      classType={rv.type}
      sleeps={rv.how_many_it_sleeps}
      searchScore={rv.search_score}
      nightlyRate={rv.rate}
      reviewScore={rv.reviews.score}
      numberOfReviews={rv.reviews.count}
      searchLocation={searchLocation}
      distanceFromSearchLocation={rv.location.distance}
      isMinimalCard={isMinimalCard}
      imageLoading={imageLoading}
      isMobile={isMobile}
      location={rv.location.name}
      state={rv.location.state}
      lat={rv.location.lat}
      lng={rv.location.lng}
      image={rv.thumbnail.filename}
      position={position}
      length={rv.length}
      showDistance={showDistance}
      isFleet={isFleet}
      isNewListing={rv.new_listing_without_reviews}
      pricingBreakdown={rv.pricing_breakdown}
    />
  );
});
