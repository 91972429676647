import React from 'react';

import useSearchFiltersLinkProps from '../../hooks/useSearchFiltersLinkProps';
import useSearchExplorer from '../../hooks/useSearchExplorer';

function NextPageForm() {
  const { pagination } = useSearchExplorer();
  const createLinkProps = useSearchFiltersLinkProps();

  if (!pagination) return null; // this can be removed if fleet pages start using pagination

  const linkProps = createLinkProps(pagination.nextPage);

  if (!pagination.hasNextPage) {
    // don't display pager if there isn't a next page
    return null;
  }

  return (
    <a {...linkProps} className="next_page d-none d-md-flex">
      Next Page »
    </a>
  );
}

export default React.memo(NextPageForm);
