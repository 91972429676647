import React from 'react';
import styled from 'styled-components';
import { Card, ThumbnailWrapper } from './RvRow.styles';

const LoadingWrapper = styled(ThumbnailWrapper)`
  background-color: #d9d9d9;
  height: 230px;
  border-radius: 5px;
  margin-bottom: 16px;

  ${({ theme }) => theme.medium`
    margin-bottom: 0;
  `};
`;

const LoadingTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.medium`
    padding: 0 20px;
  `};
`;

const LoadingText = styled.div`
  background-color: #d9d9d9;
  border-radius: 5px;
  height: ${({ height = 18 }) => `${height}px`};
  width: ${({ width = 110 }) => `${width}px`};
  margin-bottom: ${({ mb = 16 }) => `${mb}px`};
  margin-top: ${({ marginTopAuto}) => marginTopAuto ? 'auto' : '0px'};
`;

export default function LoadingRow() {
  return (
    <Card>
      <LoadingWrapper />
      <LoadingTextWrapper>
        <LoadingText height={18} width={110} mb={8} />
        <LoadingText height={26} width={220} mb={24} />
        <LoadingText height={36} width={260} />
        <LoadingText mb={0} marginTopAuto />
      </LoadingTextWrapper>
    </Card>
  );
}
