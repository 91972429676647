import React, { useMemo, useEffect, useState } from 'react';
import { SwiperSlide } from 'swiper/react/swiper-react';
import { Pagination, Navigation, A11y, Lazy } from 'swiper';

import Icon from 'core/ui/Icon';
import { MaybeBlankDataSrcImage } from 'components/MaybeBlankImage';
import ToggleFavoriteButton from 'components/ToggleFavoriteButton';
import placeholder from 'images/placeholder-rv.png';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/modules/navigation/navigation.scss'; // Navigation module
import 'swiper/modules/pagination/pagination.scss'; // Pagination module

import { deferred } from 'core/helpers/defer';
import {
  DeliveryOverlay,
  SwiperContainer,
  ThumbnailWrapper,
  ThumbnailFavoriteOverlay,
} from './RvRow.styles';

const DEFAULT_IMAGE_WIDTH = '300';
const DEFAULT_IMAGE_HEIGHT = '230';
const imgSrcBase =
  'https://d3adfz34ynqwkr.cloudfront.net/image/upload/w_295,h_228,c_fill,g_center,f_auto,q_auto/rvs-images';

const createImageSource = img => {
  if (img.includes('/assets/media/images/placeholder-rv')) {
    return {
      src: img,
      srcSet: img,
    };
  }

  return {
    src: `${imgSrcBase}/${img}`,
    srcSet: `${imgSrcBase}/${img}, https://d3adfz34ynqwkr.cloudfront.net/image/upload/w_295,h_228,c_fill,g_center,f_auto,q_auto,dpr_2.0/rvs-images/${img} 2x`,
  };
};

const Thumbnail = ({
  images,
  offersDelivery,
  lazyLoad,
  isMobile,
  isRvMap,
  rvId,
  ownerId,
  year,
  make,
  model,
  headline,
  nightlyRate,
  isFavorited,
  classType,
  lat,
  lng,
  sleeps,
  href,
  linkTarget,
  position,
}) => {
  const thumbnailImages = useMemo(() => images.map(createImageSource), [images]);
  const [isInitialRender, setIsInitialRender] = useState(true);

  useEffect(() => {
    setIsInitialRender(false);
  }, []);

  // initial (ssr) render has the first slide one position to the right for some reason
  // we adjust it in RvRow.styles.js#SwiperContainer for the initial render but we need to adjust it back here
  const fixSlidePositioning = swiper => {
    swiper.$el[0].querySelectorAll('.swiper-slide[data-swiper-slide-index="0"]').forEach(e => {
      e.style.left = 0;
    });
  };

  // we honestly don't care too much about the classes on the slides getting updated so let's have it run async
  // this improves responsiveness on the carousel
  const deferSwiperEvents = swiper => {
    if (swiper.updateSlidesClasses) {
      // eslint-disable-next-line no-param-reassign
      swiper.updateSlidesClasses = deferred(swiper.updateSlidesClasses.bind(swiper));
    }
  };

  // show a placeholder if the rv somehow doesnt have any images
  const resolvedImages = thumbnailImages.length === 0 ? [{ src: placeholder }] : thumbnailImages;
  const isFirstImageOnPage = position === 1;

  return (
    <ThumbnailWrapper isRvMap={isRvMap} data-testid="slider-thumbnail">
      <SwiperContainer
        modules={[Pagination, Navigation, A11y, Lazy]}
        pagination={{
          dynamicBullets: true,
          dynamicMainBullets: 5,
        }}
        loop
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        a11y={{
          prevSlideMessage: 'Previous slide',
          nextSlideMessage: 'Next slide',
        }}
        lazy={{
          loadPrevNext: true,
          loadPrevNextAmount: 3,
        }}
        preloadImages={false}
        preventClicks={false}
        onInit={fixSlidePositioning}
        onSwiper={deferSwiperEvents}
      >
        {resolvedImages.map(({ src, srcSet }, i) => {
          const isFirstImageInCarousel = i === 0;

          // lazy-load nonfirst carousel images and first carousel images that are marked for lazy-loading
          const shouldLazyLoad = !isFirstImageInCarousel || lazyLoad;

          // prioritize LCP image, deprioritize nonfirst carousel images
          let imagePriority;
          if (isFirstImageInCarousel) {
            imagePriority = isFirstImageOnPage ? 'high' : 'auto';
          } else {
            imagePriority = 'low';
          }

          return (
            // eslint-disable-next-line react/no-array-index-key
            <SwiperSlide key={`${src}-${i}`}>
              <a
                aria-labelledby={linkTarget}
                href={href}
                target={linkTarget}
                data-event-category="RV Card"
                data-event-action="RV Card"
                data-event-label="RV Card"
                data-event-index={position}
              >
                <MaybeBlankDataSrcImage
                  height={DEFAULT_IMAGE_HEIGHT}
                  width={DEFAULT_IMAGE_WIDTH}
                  data-src={src}
                  data-srcset={srcSet}
                  alt={headline}
                  initialSrc={shouldLazyLoad ? undefined : src}
                  initialSrcSet={shouldLazyLoad ? undefined : srcSet}
                  className={shouldLazyLoad ? 'swiper-lazy' : undefined}
                  fetchpriority={imagePriority}
                  data-testid={`slider-thumbnail-image-${i}`}
                />
              </a>
            </SwiperSlide>
          );
        })}

        {/* hide these on initial render since the carousel wont work yet anyway */}
        <div
          className="swiper-button-prev"
          style={{ display: isInitialRender ? 'none' : undefined }}
        >
          <Icon size="lg" icon="chevron-left" aria-hidden="true" />
        </div>
        <div
          className="swiper-button-next"
          style={{ display: isInitialRender ? 'none' : undefined }}
        >
          <Icon size="lg" icon="chevron-right" aria-hidden="true" />
        </div>

        {offersDelivery && (
          <DeliveryOverlay className="delivery-overlay">Offers Delivery</DeliveryOverlay>
        )}

        {isMobile ||
          (isRvMap && (
            <ThumbnailFavoriteOverlay>
              <ToggleFavoriteButton
                {...{
                  rvId,
                  ownerId,
                  year,
                  make,
                  model,
                  headline,
                  nightlyRate,
                  offersDelivery,
                  isFavorited,
                  classType,
                  location: {
                    lat,
                    log: lng,
                  },
                  sleeps,
                  images: thumbnailImages,
                }}
              />
            </ThumbnailFavoriteOverlay>
          ))}
      </SwiperContainer>
    </ThumbnailWrapper>
  );
};

export default Thumbnail;
