import React from 'react';

import * as Formatters from 'core/helpers/Formatters';

import { useImpressionTracker } from 'impression-tracker-react-hook';
import { eventLogger, handleError } from '../../../tracking/PromotedAi';
import {
  RvMapMetaWrapper,
  RvMapRateText,
  RateWrapper,
  RvMapRatingsWrapper,
  RvMapInstantBookIcon,
  LinkOverlay,
  MapCardHeadline,
  StrikethroughSpan,
  BoldSpan,
} from './RvRow.styles';

import { Card } from '../RVCard/RVCard.styles';

import Thumbnail from './Thumbnail';
import Ratings from '../RVCard/Ratings';

function RvMapCard(props) {
  const {
    isFavorited,
    isInstantBook,
    offersDelivery,
    rvId,
    year,
    ownerId,
    makeModel,
    classType,
    sleeps,
    nightlyRate,
    reviewScore,
    isMinimalCard,
    imageLoading,
    isMobile,
    images = [],
    lazyLoadImage: lazyLoad,
    position,
    isSingleImageCard,
    numberOfReviews,
    isNewListing,
    pricingBreakdown,
  } = props;

  const href = `/rvs/details/${rvId}`;
  const linkTarget = isMobile ? '_self' : `rv_${rvId}`;

  const [containerRef, impressionId, logImpressionFunctor] = useImpressionTracker({
    enable: true,
    contentId: rvId,
    handleError,
    logImpression: eventLogger.logImpression,
  });

  function handleClick() {
    logImpressionFunctor();
    eventLogger.logClick({
      impressionId,
      contentId: rvId,
      targetUrl: href,
    });
  }

  return (
    <Card data-id="rv-card" noHeight ref={containerRef}>
      <LinkOverlay
        aria-labelledby={linkTarget}
        href={href}
        onClick={handleClick}
        target={linkTarget}
        data-event-category="RV Card"
        data-event-action="RV Card"
        data-event-label="RV Card"
        data-event-index={position}
        rel="noopener noreferrer"
      />
      <Thumbnail
        {...{
          lazyLoad,
          rvId,
          images,
          isFavorited,
          ownerId,
          nightlyRate,
          offersDelivery,
          isMinimalCard,
          imageLoading,
          isMobile,
          sleeps,
          isSingleImageCard,
          href,
          linkTarget,
        }}
        isRvMap
      />

      <RvMapMetaWrapper>
        <RvMapRatingsWrapper>
          <Ratings
            score={reviewScore}
            numberOfReviews={numberOfReviews}
            isNewListing={isNewListing}
            mapCard
          />
        </RvMapRatingsWrapper>
        <MapCardHeadline>
          <h4>{makeModel}</h4>
          <h5>
            {year} {classType}
          </h5>
        </MapCardHeadline>
        <RateWrapper>
          <RvMapRateText>
            {isInstantBook && <RvMapInstantBookIcon />}
            {pricingBreakdown?.discounts?.value < 0 && (
              <>
                <StrikethroughSpan>
                  {Formatters.currency(pricingBreakdown?.non_discounted_rate?.value)}
                </StrikethroughSpan>{' '}
              </>
            )}
            <BoldSpan>{Formatters.currency(nightlyRate)}</BoldSpan>
          </RvMapRateText>
        </RateWrapper>
      </RvMapMetaWrapper>
    </Card>
  );
}

export default RvMapCard;
