import React from 'react';
import styled from 'styled-components';
import { Tooltip } from 'components/Tippy';
import * as Formatters from 'core/helpers/Formatters';

import SafeTracking from '../../../tracking/SafeTracking';

const StyledSpan = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;

const StyledPricingLine = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
`;

const PopOverDiv = styled.div`
  min-width: 200px;
`;

const segmentMetrics = (nightlyRate, priceItems) => {
  const prices = {};

  prices.pricePerNight = nightlyRate;

  Object.entries(priceItems).forEach(([key, {value}]) => {
    switch (key) {
      case 'rental_price':
        prices.nightlyRateTotal = value;
        break;
      case 'service_fee':
        prices.serviceFee = value;
        break;
      case 'insurance':
        prices.insurance = value;
        break;
      case 'owner_fees':
        prices.requiredFeeAmount = value;
        break;
      case 'total_price':
        prices.totalCost = value;
        break;
      default:
        break;
    }
  })

  SafeTracking.track('Total Price on SERP Clicked', prices);
}

const TotalPrice = ({ nightlyRate, pricingBreakdown, text }) => {
  const totalPrice = pricingBreakdown?.total_price.value || null;
  if (!totalPrice) {
    return null;
  }

  return (
    <Tooltip
      content={
        <PopOverDiv>
          {Object.entries(pricingBreakdown).map(
            ([key, { label, value }]) =>
              value !== 0 &&
              key !== 'total_price' && key !== 'non_discounted_rate' && (
                <StyledPricingLine key={key}>
                  <span>{label}: </span>
                  <span>{Formatters.currency(value)}</span>
                </StyledPricingLine>
              )
          )}
        </PopOverDiv>
      }
      placement="top"
      trigger="click"
      theme="light-border"
      duration={100}
      onShow={() => segmentMetrics(nightlyRate, pricingBreakdown)}
    >
      <StyledSpan className="total-price">
        {Formatters.currency(totalPrice)} {text}
      </StyledSpan>
    </Tooltip>
  );
};
export default TotalPrice;
